import { Control, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router';

import { LoginFormValues } from '@/modules/auth/types/login-form-values';

type Props = Readonly<{
  control: Control<LoginFormValues>;
}>;

export const ResetPasswordLink = ({ control }: Props) => {
  const { t } = useTranslation('auth');
  const email = useWatch({ control, name: 'email' });

  return (
    <Link
      to={`/auth/reset-password?email=${email}`}
      className="focusable ml-auto text-sm text-primary underline-offset-2 hover:underline"
      tabIndex={999}
    >
      {t('login.form.forgotten-password-link')}
    </Link>
  );
};
