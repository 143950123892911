import { yupResolver } from '@hookform/resolvers/yup';
import { AlertCircleIcon } from 'lucide-react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParam } from 'react-use';
import * as Yup from 'yup';

import { Field } from '@/components/shared/form/field';
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { useRequestUserPasswordResetMutation } from '@/hooks/mutations/use-request-user-password-reset-mutation';
import { CLIENT_APP } from '@/utils/consts';

type ResetPasswordFormValues = {
  email: string;
};

const validationSchema = Yup.object().shape({
  email: Yup.string().email().required(),
});

type Props = Readonly<{
  onSuccess: (email: string) => void;
}>;

export const ResetPasswordForm = ({ onSuccess }: Props) => {
  const { t } = useTranslation('auth');
  const email = useSearchParam('email') ?? '';
  const { mutateAsync: request, error } = useRequestUserPasswordResetMutation();

  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<ResetPasswordFormValues>({
    defaultValues: {
      email: email,
    },
    resolver: yupResolver(validationSchema),
  });

  const submit = async ({ email }: ResetPasswordFormValues) =>
    request({ email, clientApp: CLIENT_APP }, { onSuccess: (_, { email }) => onSuccess(email) });

  return (
    <form noValidate spellCheck={false} aria-label="Reset password" className="flex flex-col gap-6" onSubmit={handleSubmit(submit)}>
      <Field id="email" label={t('reset-password.form-email')}>
        <Input id="email" type="email" placeholder="name@example.com" {...register('email')} />
      </Field>

      <Button type="submit" block disabled={isSubmitting}>
        {t('reset-password.submit')}
      </Button>

      {error && (
        <Alert variant="destructive" icon={<AlertCircleIcon />}>
          <AlertTitle>{t('reset-password.fail.title')}</AlertTitle>
          <AlertDescription>{t('reset-password.fail.description')}</AlertDescription>
        </Alert>
      )}
    </form>
  );
};
