import { Trans } from 'react-i18next';

export const LoginFormFooter = () => (
  <div className="text-center text-sm text-muted-foreground">
    <Trans
      ns="auth"
      i18nKey="login.footer"
      components={[<a href="https://timewavehr.com?ref=app-login" className="focusable underline underline-offset-4 hover:text-primary" />]}
    />
  </div>
);
