export const ENVIRONMENT = String(import.meta.env.VITE_ENVIRONMENT);

export const API_URL = import.meta.env.MODE === 'test' ? 'https://api.vitest.timewavehr.com' : String(import.meta.env.VITE_API_URL);

export const LOCAL_STORAGE_PREFIX = 'timewave:';
export const TOKEN_STORAGE_KEY = 'auth.token';
export const EMAIL_STORAGE_KEY = 'auth.email';
export const THEME_STORAGE_KEY = 'ui-theme';

export const DEFAULT_THEME = 'system';

export const TELEMETRY_ENABLED = import.meta.env.VITE_TELEMETRY_ENABLED === 'true';
export const SENTRY_DSN = String(import.meta.env.VITE_SENTRY_DSN);
export const MIXPANEL_TOKEN = String(import.meta.env.VITE_MIXPANEL_TOKEN);
export const GROWTHBOOK_CLIENT_KEY = String(import.meta.env.VITE_GROWTHBOOK_CLIENT_KEY);
export const AZURE_MAPS_SUBSCRIPTION_KEY = String(import.meta.env.VITE_AZURE_MAPS_SUBSCRIPTION_KEY);
