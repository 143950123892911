import { queryOptions } from '@tanstack/react-query';

import { ProblemDetails, UserPasswordResetTokenVerificationsService } from '@/api';
import { QueryKey } from '@/utils/query-key';

export const createVerifyPasswordResetTokenQuery = (email: string, token: string) =>
  queryOptions<void, ProblemDetails>({
    queryKey: [QueryKey.RESET_PASSWORD_TOKEN_VERIFICATION, { email, token }],
    queryFn: () => UserPasswordResetTokenVerificationsService.verifyUserPasswordResetToken({ email, token }),
    gcTime: Infinity,
    refetchOnWindowFocus: false,
    retry: false,
  });
