import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AuthHeading } from '@/modules/auth/components/auth-heading';
import { ResetPasswordForm } from '@/modules/auth/components/reset-password/reset-password-form';

export const ResetPasswordContent = () => {
  const { t } = useTranslation('auth');
  const [usedEmail, setUsedEmail] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);

  const handleSuccess = (email: string) => {
    setUsedEmail(email);
    setIsSuccess(true);
  };

  return (
    <>
      {isSuccess ? (
        <AuthHeading title={t('reset-password.title')} subtitle={t('reset-password.success-subtitle', { email: usedEmail })} />
      ) : (
        <>
          <AuthHeading title={t('reset-password.title')} subtitle={t('reset-password.subtitle')} />
          <ResetPasswordForm onSuccess={handleSuccess} />
        </>
      )}
    </>
  );
};
