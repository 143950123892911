type Props = Readonly<{
  title: string;
  subtitle: string;
}>;

export const AuthHeading = ({ title, subtitle }: Props) => {
  return (
    <div className="mb-6 flex flex-col items-center text-center">
      <h1 className="mb-2 text-xl font-semibold">{title}</h1>
      <p className="text-muted-foreground text-balance text-sm">{subtitle}</p>
    </div>
  );
};
