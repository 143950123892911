import { useTranslation } from 'react-i18next';

import { BackToLoginLink } from '@/modules/auth/components/back-to-login-link';
import { ResetPasswordContent } from '@/modules/auth/components/reset-password';

export const ResetPasswordPage = () => {
  const { t } = useTranslation('auth');

  return (
    <>
      <title>{t('reset-password.title')}</title>

      <ResetPasswordContent />
      <BackToLoginLink />
    </>
  );
};
