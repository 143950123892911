import { createContext, PropsWithChildren, useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';

import { DEFAULT_THEME, THEME_STORAGE_KEY } from '@/config';
import { usePersistState } from '@/hooks/use-persist-state';

type Theme = 'dark' | 'light' | 'system';

type ThemeProviderState = {
  theme: Theme;
  setTheme: (theme: Theme) => void;
};

const initialState: ThemeProviderState = {
  theme: 'system',
  setTheme: () => null,
};

const usePersistTheme = () => usePersistState<Theme>(THEME_STORAGE_KEY, { defaultValue: DEFAULT_THEME, defaultServerValue: DEFAULT_THEME });

const ThemeProviderContext = createContext<ThemeProviderState>(initialState);

const ThemeProvider = ({ children, ...props }: PropsWithChildren) => {
  const [theme, setTheme] = usePersistTheme();

  useEffect(() => {
    const root = window.document.body;

    root.classList.remove('light', 'dark');

    if (theme === 'system') {
      const systemTheme = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';

      root.classList.add(systemTheme);
      return;
    }

    root.classList.add(theme!);
  }, [theme]);

  const value = useMemo(
    () => ({
      theme: theme!,
      setTheme,
    }),
    [theme, setTheme],
  );

  return (
    <ThemeProviderContext.Provider {...props} value={value}>
      <Helmet
        bodyAttributes={{
          class: getThemeClassName(theme ?? DEFAULT_THEME),
        }}
      />
      {children}
    </ThemeProviderContext.Provider>
  );
};

export const getThemeClassName = (theme: Theme) => {
  if (typeof window !== 'undefined' && theme === 'system') {
    return window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
  }

  return theme === 'system' ? 'light' : theme;
};

export { ThemeProvider, ThemeProviderContext, type Theme };
