import { useTranslation } from 'react-i18next';

import { AsyncPage } from '@/components/base/async-page';
import { PageSpinner } from '@/components/shared/spinner/page-spinner';
import { ChangePasswordContent } from '@/modules/auth/components/change-password/change-password-content';
import { ExpiredTokenError } from '@/modules/auth/components/change-password/expired-token-error';
import { type ChangePasswordPageData } from '@/modules/auth/loaders/use-change-password-page-loader';

export const ChangePasswordPage = () => {
  const { t } = useTranslation('auth');

  return (
    <>
      <title>{t('change-password.title')}</title>

      <AsyncPage
        errorFallback={<ExpiredTokenError />}
        loadingfallback={
          <div className="flex min-h-56 items-center justify-center">
            <PageSpinner />
          </div>
        }
      >
        {(data: ChangePasswordPageData) => <ChangePasswordContent {...data} />}
      </AsyncPage>
    </>
  );
};
