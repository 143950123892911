import { ReactNode, Suspense } from 'react';
import { Await, useLoaderData } from 'react-router';

import { PageSpinner } from '@/components/shared/spinner/page-spinner';

type Props<TData> = Readonly<{
  errorFallback?: ReactNode;
  loadingfallback?: ReactNode;
  children: (data: TData) => ReactNode;
}>;

const DataErrorScreen = () => <div>Error loading data</div>;

export const AsyncPage = <TData,>({ errorFallback, loadingfallback, children }: Props<TData>) => {
  const loader = useLoaderData();

  return (
    <Suspense fallback={loadingfallback ?? <PageSpinner />}>
      <Await resolve={loader.data} errorElement={errorFallback ?? <DataErrorScreen />}>
        {(data: TData) => children(data)}
      </Await>
    </Suspense>
  );
};
