import { PropsWithChildren } from 'react';

import { CommandPaletteProvider } from '@/components/context/command-palette-context';
import { PreferencesDialogProvider } from '@/components/context/preferences-dialog-context';
import { ThemeProvider } from '@/components/context/theme-context';
import { SidebarProvider } from '@/components/ui/sidebar';
import { TooltipProvider } from '@/components/ui/tooltip';

export const ComponentProvider = ({ children }: PropsWithChildren) => (
  <ThemeProvider>
    <TooltipProvider delayDuration={100} skipDelayDuration={50}>
      <PreferencesDialogProvider>
        <CommandPaletteProvider>
          <SidebarProvider>{children}</SidebarProvider>
        </CommandPaletteProvider>
      </PreferencesDialogProvider>
    </TooltipProvider>
  </ThemeProvider>
);
