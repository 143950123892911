/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ResetUserPasswordRequest } from '../models/ResetUserPasswordRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class UserPasswordResetsService {
  /**
   * @param requestBody
   * @returns any OK
   * @throws ApiError
   */
  public static updateUserPassword(
    requestBody?: ResetUserPasswordRequest,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/user-password-resets',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
}
