import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { Field } from '@/components/shared/form/field';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';

export type ChangePasswordFormValues = {
  newPassword: string;
  confirmPassword: string;
};

type Props = Readonly<{
  onSubmit: (data: ChangePasswordFormValues) => void;
}>;

export const ChangePasswordForm = ({ onSubmit }: Props) => {
  const { t } = useTranslation('auth');

  const validationSchema = Yup.object().shape({
    newPassword: Yup.string().required(t('change-password.form.password.required')),
    confirmPassword: Yup.string()
      .required(t('change-password.form.confirm-password.required'))
      .oneOf([Yup.ref('newPassword'), ''], t('change-password.form.confirm-password.match')),
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<ChangePasswordFormValues>({
    defaultValues: {
      newPassword: '',
      confirmPassword: '',
    },
    resolver: yupResolver(validationSchema),
  });

  return (
    <form noValidate spellCheck={false} aria-label={t('change-password.title')} className="grid gap-6" onSubmit={handleSubmit(onSubmit)}>
      <Field id="newPassword" label={t('change-password.form.password')} error={errors.newPassword?.message}>
        <Input id="newPassword" type="password" autoComplete="new-password" placeholder="••••••••" {...register('newPassword')} />
      </Field>

      <Field id="confirmPassword" label={t('change-password.form.confirm-password')} error={errors.confirmPassword?.message}>
        <Input id="confirmPassword" type="password" autoComplete="new-password" placeholder="••••••••" {...register('confirmPassword')} />
      </Field>

      <Button type="submit" block disabled={isSubmitting}>
        {t('change-password.submit')}
      </Button>
    </form>
  );
};
