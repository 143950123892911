import { LoaderCircle } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import { cn } from '@/utils/cn';

type Props = {
  className?: string;
};
export const InlineSpinner = ({ className }: Props) => {
  const { t } = useTranslation('general');

  return <LoaderCircle role="presentation" aria-label={t('loading')} className={cn('size-4 animate-spin text-foreground', className)} />;
};
