/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { RequestUserPasswordResetRequest } from '../models/RequestUserPasswordResetRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class UserPasswordResetRequestsService {
  /**
   * @param requestBody
   * @returns any OK
   * @throws ApiError
   */
  public static requestUserPasswordReset(
    requestBody?: RequestUserPasswordResetRequest,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/user-password-reset-requests',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
}
