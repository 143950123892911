/* eslint-disable */
import { yupResolver } from '@hookform/resolvers/yup';
import { AlertCircleIcon } from 'lucide-react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import * as Yup from 'yup';

import { Field } from '@/components/shared/form/field';
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { EMAIL_STORAGE_KEY } from '@/config';
import { usePersistState } from '@/hooks/use-persist-state';
import { LoginFormFooter } from '@/modules/auth/components/login-form-footer';
import { useTranslation } from 'react-i18next';
import { useAuth } from '@/hooks/use-auth';
import { ResetPasswordLink } from '@/modules/auth/components/reset-password-link';

type LoginFormValues = {
  email: string;
  password: string;
};

const validationSchema = Yup.object().shape({
  email: Yup.string().required().email(),
  password: Yup.string().required(),
});

export const LoginForm = () => {
  const { t } = useTranslation('auth');
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  const [defaultEmail] = usePersistState(EMAIL_STORAGE_KEY, { defaultValue: '' });
  const { login } = useAuth();

  const {
    control,
    register,
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = useForm<LoginFormValues>({
    defaultValues: {
      email: defaultEmail ?? '',
      password: '',
    },
    resolver: yupResolver(validationSchema),
  });

  const submit = async ({ email, password }: LoginFormValues) => {
    try {
      const result = await login(email, password);
      if (!result) {
        setError(true);
        setValue('password', '');

        return;
      }
    } catch {
      setError(true);
      setValue('password', '');
      return;
    }

    navigate('/');
  };

  return (
    <>
      <form noValidate spellCheck={false} aria-label="Sign in to Timewave" className="flex flex-col gap-6" onSubmit={handleSubmit(submit)}>
        <Field id="email" label={t('login.form.email')}>
          <Input id="email" type="email" placeholder="name@example.com" {...register('email')} />
        </Field>
        <Field id="password" label={t('login.form.password')} Suffix={<ResetPasswordLink control={control} />}>
          <Input id="password" type="password" placeholder="••••••••" {...register('password')} />
        </Field>

        <Button type="submit" block pending={isSubmitting}>
          {t('login.submit')}
        </Button>

        {error && (
          <Alert variant="destructive" icon={<AlertCircleIcon />}>
            <AlertTitle>{t('login.fail.title')}</AlertTitle>
            <AlertDescription>{t('login.fail.description')}</AlertDescription>
          </Alert>
        )}
      </form>

      <LoginFormFooter />
    </>
  );
};
