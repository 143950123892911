import { createContext, PropsWithChildren, useMemo, useState } from 'react';

type PreferencesDialogState = {
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
};

const initialState: PreferencesDialogState = {
  isOpen: false,
  setOpen: () => void 0,
};

const PreferencesDialogContext = createContext<PreferencesDialogState>(initialState);

const PreferencesDialogProvider = ({ children }: PropsWithChildren) => {
  const [isOpen, setIsOpen] = useState(false);

  const value = useMemo(
    () => ({
      isOpen,
      setOpen: setIsOpen,
    }),
    [isOpen],
  );

  return <PreferencesDialogContext.Provider value={value}>{children}</PreferencesDialogContext.Provider>;
};

export { PreferencesDialogContext, PreferencesDialogProvider };
