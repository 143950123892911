import { AlertCircleIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import { ChangePasswordForm, ChangePasswordFormValues } from './change-password-form';

import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import { useResetUserPasswordMutation } from '@/hooks/mutations/use-reset-user-password-mutation';
import { AuthHeading } from '@/modules/auth/components/auth-heading';
import { BackToLoginLink } from '@/modules/auth/components/back-to-login-link';

type Props = Readonly<{
  email: string;
  token: string;
}>;

export const ChangePasswordContent = ({ email, token }: Props) => {
  const { t } = useTranslation('auth');
  const { mutateAsync: changePassword, isSuccess, isError } = useResetUserPasswordMutation();

  const submit = async ({ newPassword }: ChangePasswordFormValues) => changePassword({ email, token, newPassword });

  return (
    <>
      {isSuccess ? (
        <AuthHeading title={t('change-password.success.title')} subtitle={t('change-password.success.description')} />
      ) : (
        <>
          <AuthHeading title={t('change-password.heading')} subtitle={t('change-password.description')} />
          <ChangePasswordForm onSubmit={submit} />
          {isError && (
            <Alert variant="destructive" icon={<AlertCircleIcon />}>
              <AlertTitle>{t('change-password.fail.title')}</AlertTitle>
              <AlertDescription>{t('change-password.fail.description')}</AlertDescription>
            </Alert>
          )}
        </>
      )}
      <BackToLoginLink />
    </>
  );
};
