/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback } from 'react';

import { UserProfileDto, UserProfileMembershipDto } from '@/api';
import { TELEMETRY_ENABLED } from '@/config';
import { useMixpanel } from '@/hooks/use-mixpanel';
import { EVENT_NAME } from '@/utils/consts';

export const useAnalytics = () => {
  const mixpanel = useMixpanel();

  const track = useCallback(
    (event: string, properties?: Record<string, string | number | boolean>) => {
      if (!TELEMETRY_ENABLED) {
        return;
      }

      if (mixpanel) {
        mixpanel.track(event, properties);
      }
    },
    [mixpanel],
  );

  const identify = useCallback(
    (user: UserProfileDto, membership?: UserProfileMembershipDto) => {
      if ((window as any).Beacon) {
        (window as any).Beacon(
          'identify',
          membership?.pictureUrl
            ? {
                name: membership.preferredName || membership.fullName,
                email: user.email,
                avatar: membership?.pictureUrl,
              }
            : {
                name: membership?.preferredName || membership?.fullName,
                email: user.email,
              },
        );
      }

      if (!TELEMETRY_ENABLED) {
        return;
      }

      if (mixpanel) {
        mixpanel.identify(user.id);
        mixpanel.people.set({
          $email: user.email,
          $name: membership?.preferredName || membership?.fullName,
          $avatar: membership?.pictureUrl,
          'Account ID': membership?.account?.id,
          'Company Name': membership?.account?.companyName,
          'Role ID': membership?.role.id,
        });
      }
    },
    [mixpanel],
  );

  const trackPageView = useCallback(
    (path: string) => {
      if (!TELEMETRY_ENABLED) {
        return;
      }

      if (mixpanel) {
        mixpanel.track(EVENT_NAME.PAGE.VIEWED, { path });
      }
    },
    [mixpanel],
  );

  return { track, identify, trackPageView };
};
