import { Trans, useTranslation } from 'react-i18next';
import { Outlet } from 'react-router';

import { ModeToggle } from '../shared/mode-toggle';

import { Logo } from '@/components/shared/logo';
import { Card, CardContent } from '@/components/ui/card';
import { useTrackPageView } from '@/hooks/use-track-page-view';

export const AuthLayout = () => {
  const { t } = useTranslation(['general', 'auth']);

  useTrackPageView();

  return (
    <div className="flex min-h-svh flex-col items-center justify-center bg-neutral-50 p-6 md:p-10">
      <div className="w-full max-w-sm md:max-w-4xl">
        <div className="flex flex-col gap-2 md:gap-1">
          <Card className="overflow-hidden">
            <CardContent className="grid p-0 md:grid-cols-2">
              <div className="bg-white">
                <div className="px-6 py-8 md:px-8 md:py-10">
                  <Logo className="mb-8 h-7 w-auto" />
                  <div className="flex flex-col gap-6">
                    <Outlet />
                  </div>
                </div>
              </div>

              <div className="relative hidden bg-muted md:block">
                <img
                  src="/images/auth-bg.jpg"
                  alt=""
                  role="presentation"
                  className="absolute inset-0 h-full w-full object-cover object-center dark:hidden"
                />
                <img
                  src="/images/auth-bg-dark.jpg"
                  alt=""
                  role="presentation"
                  className="absolute inset-0 hidden h-full w-full object-cover object-center dark:block"
                />
              </div>
            </CardContent>
          </Card>
          <div className="flex w-full items-center justify-between">
            <div className="text-balance text-xs text-muted-foreground [&_a]:underline [&_a]:underline-offset-4 hover:[&_a]:text-primary">
              <Trans
                i18nKey="login.continuing-terms-and-conditions"
                ns="auth"
                components={[
                  <a href={t('general:terms-url')} target="_blank" rel="noopener" className="focusable" />,
                  <a href={t('general:policy-url')} target="_blank" rel="noopener" className="focusable" />,
                ]}
              />
            </div>
            <ModeToggle variant="text" />
          </div>
        </div>
      </div>
    </div>
  );
};
