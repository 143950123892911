import { cva, type VariantProps } from 'class-variance-authority';
import { InfoIcon } from 'lucide-react';
import * as React from 'react';

import { cn } from '@/utils/cn';

const alertVariants = cva(
  'relative w-full rounded-lg text-secondary-foreground border border-border pl-[52px] pr-4 py-4 text-sm [&>svg+div]:translate-y-[-3px] [&>svg]:absolute [&>svg]:left-4 [&>svg]:top-4 [&>svg]:text-foreground',
  {
    variants: {
      variant: {
        default: 'bg-background [&>svg]:text-accent-foreground [&>svg]:size-5',
        destructive: 'bg-background [&>svg]:text-destructive [&>svg]:size-5',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
);

type AlertProps = React.HTMLAttributes<HTMLDivElement> &
  VariantProps<typeof alertVariants> & {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    icon?: any;
  };

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(({ className, variant, icon, children, ...props }, ref) => (
  <div ref={ref} role="alert" className={cn(alertVariants({ variant }), className)} {...props}>
    {icon ?? <InfoIcon />}
    <div
      className={cn('absolute left-[13px] top-[16px] size-[26px] rounded-full border-[1.5px]', {
        'border-destructive/50': variant === 'destructive',
        'border-accent-foreground/30': variant === 'default',
      })}
    />
    <div
      className={cn('absolute left-[10px] top-[10px] size-[32px] rounded-full border-[1.5px]', {
        'border-destructive/20': variant === 'destructive',
        'border-accent-foreground/10': variant === 'default',
      })}
    />
    {children}
  </div>
));
Alert.displayName = 'Alert';

const AlertTitle = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLHeadingElement>>(({ className, ...props }, ref) => (
  <h5 ref={ref} className={cn('mb-1 font-semibold tracking-tight', className)} {...props} />
));
AlertTitle.displayName = 'AlertTitle';

const AlertDescription = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLParagraphElement>>(
  ({ className, ...props }, ref) => (
    <div ref={ref} className={cn('text-sm text-muted-foreground [&_p]:leading-relaxed', className)} {...props} />
  ),
);
AlertDescription.displayName = 'AlertDescription';

export { Alert, AlertTitle, AlertDescription };
