import { useTranslation } from 'react-i18next';
import { Link } from 'react-router';

import { Button } from '@/components/ui/button';

export const BackToLoginLink = () => {
  const { t } = useTranslation('auth');

  return (
    <Button asChild variant="link" className="inline-flex">
      <Link to="/auth/login">{t('back-to-login-link')}</Link>
    </Button>
  );
};
