import axios from 'axios';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import 'date-fns/locale/en-US';
import 'date-fns/locale/pl';

import { App } from '@/components/base/app';
import { AppProviders } from '@/components/providers/app-providers';
import { API_URL } from '@/config';

import './styles/app.css';
import './i18n';
import './instrument';

axios.defaults.baseURL = API_URL;

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <AppProviders>
      <App />
    </AppProviders>
  </StrictMode>,
);
