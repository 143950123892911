import { FeaturesReady, GrowthBook, GrowthBookProvider as NativeGrowthBookProvider } from '@growthbook/growthbook-react';
import { PropsWithChildren, useEffect } from 'react';

import { FullPageSpinner } from '@/components/shared/spinner/full-page-spinner';
import { ENVIRONMENT, GROWTHBOOK_CLIENT_KEY } from '@/config';
import { useAccountId, useCurrentUser } from '@/store/auth.slice';

const gb = new GrowthBook({
  apiHost: 'https://cdn.growthbook.io',
  clientKey: GROWTHBOOK_CLIENT_KEY,
  enableDevMode: ENVIRONMENT === 'development',
});
gb.init({ streaming: true }).then();

export const GrowthbookProvider = ({ children }: PropsWithChildren) => {
  const user = useCurrentUser();
  const organizationId = useAccountId();

  useEffect(() => {
    if (user && organizationId) {
      gb.setAttributes({
        userId: user.id,
        email: user.email,
        organizationId,
      }).then();
    }
  }, [organizationId, user]);

  return (
    <NativeGrowthBookProvider growthbook={gb}>
      <FeaturesReady fallback={<FullPageSpinner />}>{children}</FeaturesReady>
    </NativeGrowthBookProvider>
  );
};
