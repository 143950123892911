import { lazy } from 'react';

import { GuestApp } from '@/components/base/app/guest-app';
import { GrowthbookProvider } from '@/components/providers/growthbook-provider';
import { useBeacon } from '@/hooks/use-beacon';
import { useIsLoggedIn } from '@/store/auth.slice';

const AuthenticatedApp = lazy(() => import('./authenticated-app'));

const App = () => {
  const isLoggedIn = useIsLoggedIn();

  useBeacon();

  return (
    <>
      <GrowthbookProvider>{isLoggedIn ? <AuthenticatedApp /> : <GuestApp />}</GrowthbookProvider>
    </>
  );
};

export default App;
