import { TriangleAlertIcon } from 'lucide-react';

export const GlobalErrorBoundary = ({ error }: Readonly<{ error?: Error }>) => {
  return (
    <div className="flex h-full flex-col items-center justify-center space-y-4 py-32">
      <div className="h-32 w-32 text-destructive">
        <TriangleAlertIcon className="h-full w-full" />
      </div>

      <h1 className="text-3xl font-semibold">{error?.name ?? 'Something went wrong'}</h1>
      <p className="text-center text-lg text-muted-foreground">{error?.message ?? 'Sorry about that. Please try refreshing the page.'}</p>
    </div>
  );
};
