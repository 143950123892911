import { useTranslation } from 'react-i18next';

import { AuthHeading } from '@/modules/auth/components/auth-heading';
import { LoginForm } from '@/modules/auth/components/login-form';

export const LoginPage = () => {
  const { t } = useTranslation('auth');

  return (
    <>
      <title>{t('login.title')}</title>

      <AuthHeading title={t('login.title')} subtitle={t('login.subtitle')} />
      <LoginForm />
    </>
  );
};
