import { createContext, PropsWithChildren, useMemo, useState } from 'react';

type CommandPaletteState = {
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
};

const initialState: CommandPaletteState = {
  isOpen: false,
  setOpen: () => void 0,
};

const CommandPaletteContext = createContext<CommandPaletteState>(initialState);

const CommandPaletteProvider = ({ children }: PropsWithChildren) => {
  const [isOpen, setIsOpen] = useState(false);

  const value = useMemo(
    () => ({
      isOpen,
      setOpen: setIsOpen,
    }),
    [isOpen],
  );

  return <CommandPaletteContext.Provider value={value}>{children}</CommandPaletteContext.Provider>;
};

export { CommandPaletteContext, CommandPaletteProvider };
