import { AlertCircle } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import { BackToLoginLink } from '@/modules/auth/components/back-to-login-link';

export const ExpiredTokenError = () => {
  const { t } = useTranslation('auth');

  return (
    <>
      <Alert variant="destructive">
        <AlertCircle className="size-4" />
        <AlertTitle>{t('change-password.expired-token.title')}</AlertTitle>
        <AlertDescription>{t('change-password.expired-token.description')}</AlertDescription>
      </Alert>
      <BackToLoginLink />
    </>
  );
};
