import axios from 'axios';
import { createContext, PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { FullPageSpinner } from '@/components/shared/spinner/full-page-spinner';
import { useAnalytics } from '@/hooks/use-analytics';
import { AppDispatch } from '@/store';
import { clearContext, clearToken, setToken, setUser, useInitialAuthContext } from '@/store/auth.slice';
import { AuthService } from '@/utils/auth';

type AuthContextValue = {
  login: (email: string, password: string) => Promise<boolean>;
  logout: () => void;
};

const AuthContext = createContext<AuthContextValue>({} as AuthContextValue);

const AuthProvider = ({ children }: PropsWithChildren) => {
  const dispatch: AppDispatch = useDispatch();
  const [pending, setPending] = useState(true);
  const { identify } = useAnalytics();
  const authContext = useInitialAuthContext();

  const value = useMemo(() => {
    const login = async (username: string, password: string): Promise<boolean> => {
      const user = await AuthService.login(username, password);
      if (!user) {
        return false;
      }

      dispatch(setUser(user));
      dispatch(setToken(AuthService.getToken()));

      identify(user, user.memberships.length > 0 ? user.memberships[0] : undefined);

      return Promise.resolve(true);
    };

    const logout = async () => {
      try {
        dispatch(setUser(null));
        dispatch(setToken(null));
        dispatch(clearContext());

        axios.defaults.headers.common.Authorization = '';

        await AuthService.logout().then(() => (window.location.href = '/auth/login'));
      } catch (error) {
        console.error('Error logging out', error);
      }
    };

    return {
      login,
      logout,
    };
  }, [dispatch, identify]);

  useEffect(() => {
    dispatch(setToken(AuthService.getToken()));

    AuthService.getUser(undefined, authContext?.impersonatorId ?? undefined)
      .then((user) => {
        if (!user) {
          AuthService.deleteToken();
          dispatch(clearContext());
          dispatch(clearToken());
        }

        dispatch(setUser(user));

        if (user) {
          identify(user, user.memberships.length > 0 ? user.memberships[0] : undefined);
        }
      })
      .catch(() => {
        AuthService.deleteToken();

        dispatch(clearToken());
        dispatch(clearContext());
        dispatch(setUser(null));
      })
      .finally(() => {
        setPending(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (pending) {
    return <FullPageSpinner />;
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export { AuthContext, AuthProvider };
