import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import { ENVIRONMENT } from '@/config';

// eslint-disable-next-line import/no-named-as-default-member
void i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ['en', 'es'],
    fallbackLng: 'en',
    load: 'languageOnly',
    defaultNS: ['translation', 'general', 'profile'],
    debug: ENVIRONMENT !== 'production',
  });

export default i18n;
