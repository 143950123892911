import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router';

import { ENVIRONMENT, SENTRY_DSN, TELEMETRY_ENABLED } from '@/config';

if (TELEMETRY_ENABLED && SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration({ maskAllText: false, maskAllInputs: false }),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: [
      /^\//,
      /^https:\/\/localhost:5173/,
      /^https:\/\/api\.timewavehr\.com/,
      /^https:\/\/api\.stage\.timewavehr\.com/,
    ],
    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
    environment: ENVIRONMENT,
  });
}
