import mixpanel from 'mixpanel-browser';
import { PropsWithChildren, useEffect } from 'react';

import { ENVIRONMENT, MIXPANEL_TOKEN, TELEMETRY_ENABLED } from '@/config';

const TelemetryWrappedContent = ({ children }: PropsWithChildren) => {
  useEffect(() => {
    mixpanel.init(MIXPANEL_TOKEN, {
      debug: ENVIRONMENT === 'development',
      track_pageview: false,
      persistence: 'localStorage',
    });
  }, []);

  return <>{children}</>;
};

export const TelemetryProvider = ({ children }: PropsWithChildren) =>
  TELEMETRY_ENABLED ? <TelemetryWrappedContent>{children}</TelemetryWrappedContent> : <>{children}</>;
