import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { PropsWithChildren, Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { HelmetProvider } from 'react-helmet-async';

import { GlobalErrorBoundary } from '@/components/base/global-error-boundary';
import { AuthProvider } from '@/components/context/auth-context';
import { ComponentProvider } from '@/components/providers/component-provider';
import { StoreProvider } from '@/components/providers/store-provider';
import { TelemetryProvider } from '@/components/providers/telemetry-provider';
import { FullPageSpinner } from '@/components/shared/spinner/full-page-spinner';
import { ENVIRONMENT } from '@/config';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 2,
      refetchOnWindowFocus: false,
      // Exponential backoff with jitter
      retry: ENVIRONMENT === 'development' ? false : 3,
      retryDelay(failureCount) {
        const maxDelay = 30000;
        const baseDelay = Math.min(1000 * 2 ** failureCount, maxDelay);
        const jitter = Math.random() * (maxDelay - baseDelay);

        return baseDelay + jitter;
      },
    },
  },
});

export const AppProviders = ({ children }: PropsWithChildren) => (
  <Suspense fallback={<FullPageSpinner />}>
    <ErrorBoundary fallback={<GlobalErrorBoundary />}>
      <TelemetryProvider>
        <HelmetProvider>
          <ComponentProvider>
            <QueryClientProvider client={queryClient}>
              <StoreProvider>
                <AuthProvider>{children}</AuthProvider>
              </StoreProvider>
            </QueryClientProvider>
          </ComponentProvider>
        </HelmetProvider>
      </TelemetryProvider>
    </ErrorBoundary>
  </Suspense>
);
