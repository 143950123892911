import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import isNil from 'lodash-es/isNil';
import merge from 'lodash-es/merge';
import { useSelector } from 'react-redux';

import { UserPreferencesDto, UserProfileDto, UserProfileMembershipDto } from '@/api';
import { RootState } from '@/store';

export interface AuthSliceState {
  token?: string | null;
  user: UserProfileDto | null;
  context?: {
    accountId: string;
    impersonatorId: string | null;
  };
}

const authSlice = createSlice({
  name: 'auth',
  initialState: { token: null, user: null } as AuthSliceState,
  reducers: {
    setToken(state, { payload: token }: PayloadAction<string | null>) {
      state.token = token;
    },

    clearToken(state) {
      state.token = null;
    },

    setUser(state, { payload: user }: PayloadAction<UserProfileDto | null>) {
      state.user = user;
    },

    setContext(
      state,
      {
        payload: { accountId, impersonatorId = null },
      }: PayloadAction<{
        accountId?: string;
        impersonatorId?: string | null;
      }>,
    ) {
      state.context = {
        accountId: accountId ?? state.context!.accountId,
        impersonatorId,
      };
    },

    clearContext(state) {
      state.context = undefined;
    },

    updateUserProfile(state, { payload: user }: PayloadAction<Partial<UserProfileDto>>) {
      state.user = merge({}, state.user, user);
    },

    updateUserPreferences(state, { payload: preferences }: PayloadAction<Partial<UserPreferencesDto>>) {
      if (state.user) {
        state.user.preferences = { ...state.user.preferences, ...preferences };
      }
    },

    updateMembership(
      state,
      { payload: { accountId, membership } }: PayloadAction<{ accountId: string; membership: Partial<UserProfileMembershipDto> }>,
    ) {
      if (state.user) {
        state.user.memberships = [
          ...state.user.memberships.filter((m) => m.account.id !== accountId),
          merge(
            {} as UserProfileMembershipDto,
            state.user.memberships.find((m) => m.account.id === accountId),
            membership,
          ),
        ];
      }
    },

    updateAccountSettings(
      state,
      {
        payload: { accountId, settings },
      }: PayloadAction<{
        accountId: string;
        settings: {
          companyName: string;
          logoUrl: string | null;
        };
      }>,
    ) {
      if (!state.user) {
        return;
      }

      state.user.memberships = state.user.memberships.map((membership) => {
        if (membership.account.id === accountId) {
          membership.account = { ...membership.account, ...settings };
        }

        return membership;
      });
    },
  },
});

const selectAuth = (state: RootState) => state.auth;

const getMemberships = createSelector(selectAuth, ({ user }) => {
  if (!user) {
    return [];
  }

  return user.memberships;
});

export const getAuthContext = createSelector(selectAuth, getMemberships, ({ context }, memberships) => {
  if (context?.accountId && memberships.some((m) => m.account.id === context.accountId)) {
    return { accountId: context.accountId };
  }

  if (!context && memberships.length === 1) {
    return { accountId: memberships[0].account.id };
  }

  return { accountId: null };
});
export const getAuthToken = createSelector(selectAuth, ({ token }) => token);

export const getCurrentMembership = createSelector(getAuthContext, getMemberships, ({ accountId }, memberships) =>
  memberships.find((m) => m.account.id === accountId),
);

export const getCurrentUser = createSelector(selectAuth, ({ user }) => user);

const getIsLoggedIn = createSelector(getAuthToken, getCurrentUser, (token, user) => !isNil(token) && !isNil(user));

export const useAuthContext = () => useSelector(getAuthContext);

export const useCurrentMembership = () => useSelector(getCurrentMembership)!;

const getInitialAuthContext = createSelector(selectAuth, ({ context }) => context);

export const useCurrentUser = () => useSelector(getCurrentUser)!;
export const useInitialAuthContext = () => useSelector(getInitialAuthContext);
export const useIsLoggedIn = () => useSelector(getIsLoggedIn);
export const useLocale = () => useSelector(getCurrentUser)?.preferences.locale ?? 'en-US';
export const useMemberships = () => useSelector(getMemberships);
export const useAccountId = () => useSelector(getCurrentMembership)?.account.id;

export const usePreferences = () => useSelector(getCurrentUser)!.preferences;

export const {
  setToken,
  clearToken,
  setUser,
  setContext,
  clearContext,
  updateUserProfile,
  updateUserPreferences,
  updateMembership,
  updateAccountSettings,
} = authSlice.actions;

export default authSlice;
