import { useQueryClient } from '@tanstack/react-query';
import { Params } from 'react-router';
import { useSearchParam } from 'react-use';

import { createVerifyPasswordResetTokenQuery } from '@/queries/verify-password-reset-token-query';

export type ChangePasswordPageData = {
  email: string;
  token: string;
};

export const useChangePasswordPageLoader = () => {
  const queryClient = useQueryClient();
  const email = useSearchParam('email') ?? '';

  return ({ params }: { params: Params<'token'> }) => {
    const verifyResetPasswordTokenPromise = queryClient.fetchQuery(createVerifyPasswordResetTokenQuery(email, params.token!));

    const dataPromise: Promise<ChangePasswordPageData> = Promise.all([verifyResetPasswordTokenPromise]).then(() => ({
      email,
      token: params.token!,
    }));

    return {
      data: dataPromise,
    };
  };
};
