import * as Sentry from '@sentry/react';
import axios from 'axios';

import { AuthenticationService, IdentityService, UserProfileDto } from '@/api';
import { EMAIL_STORAGE_KEY, LOCAL_STORAGE_PREFIX, TELEMETRY_ENABLED, TOKEN_STORAGE_KEY } from '@/config';

export const AuthService = {
  getToken: (): string | null => localStorage.getItem(TOKEN_STORAGE_KEY),

  saveToken: (token: string): void => localStorage.setItem(TOKEN_STORAGE_KEY, token),

  deleteToken: (): void => localStorage.removeItem(TOKEN_STORAGE_KEY),

  saveEmail: (email: string): void => localStorage.setItem(`${LOCAL_STORAGE_PREFIX}${EMAIL_STORAGE_KEY}`, JSON.stringify(email)),

  login: async (email: string, password: string): Promise<UserProfileDto | null> => {
    try {
      const { accessToken } = await AuthenticationService.createAuthToken({
        email,
        password,
      });

      AuthService.saveToken(accessToken);
      AuthService.saveEmail(email);

      return AuthService.getUser(accessToken);
    } catch {
      return Promise.resolve(null);
    }
  },

  logout: async (): Promise<void> => {
    AuthService.deleteToken();

    if (TELEMETRY_ENABLED) {
      Sentry.setUser(null);
    }

    return Promise.resolve();
  },

  async getUser(tokenOverride?: string, impersonatorId?: string): Promise<UserProfileDto | null> {
    const token = tokenOverride ?? AuthService.getToken();
    if (!token) {
      return Promise.resolve(null);
    }

    axios.defaults.headers.common = {
      authorization: `Bearer ${token}`,
      accept: 'application/json',
      'content-type': 'application/json',
      'x-impersonator-id': impersonatorId,
    };
    try {
      const user = await IdentityService.getUserProfile();
      if (TELEMETRY_ENABLED) {
        Sentry.setUser({
          id: user.id,
          email: user.email,
        });
      }

      return user;
    } catch {
      return Promise.resolve(null);
    }
  },
};
