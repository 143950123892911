import { PropsWithChildren, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

import { Label } from '@/components/ui/label';
import { cn } from '@/utils/cn';

type Props = PropsWithChildren<{
  label: string;
  id?: string;
  optional?: boolean;
  hint?: ReactNode | string;
  error?: string;
  className?: string;
  Suffix?: ReactNode;
}>;

export const Field = ({ label, id = uuid(), optional, hint, error, className, Suffix, children }: Props) => {
  const { t } = useTranslation('general');
  const helpTextId = `${id}-help`;
  const errorId = `${id}-error`;

  return (
    <div className={cn('grid gap-2', className)} role="group" aria-labelledby={`${id}-label`}>
      <Label id={`${id}-label`} htmlFor={id} className="flex w-full items-center justify-between gap-3">
        <div className="flex items-center gap-2">
          <span>{label}</span>
          {optional && (
            <span className="rounded bg-neutral-100 px-1.5 py-1 text-xs font-medium leading-none text-neutral-600 dark:bg-accent dark:text-neutral-300">
              {t('optional')}
            </span>
          )}
        </div>
        {Suffix}
      </Label>
      {hint && (
        <p id={helpTextId} className="text-xs text-muted-foreground">
          {hint}
        </p>
      )}
      {children}

      {error && (
        <div id={errorId} role="alert" aria-live="polite" className="overflow-hidden text-sm text-destructive-foreground">
          {error}
        </div>
      )}
    </div>
  );
};
