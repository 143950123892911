import { createBrowserRouter, Navigate, RouterProvider } from 'react-router';

import { AuthLayout } from '@/components/layout/auth-layout';
import { FullPageSpinner } from '@/components/shared/spinner/full-page-spinner';
import { useChangePasswordPageLoader } from '@/modules/auth/loaders/use-change-password-page-loader';
import { ChangePasswordPage } from '@/modules/auth/pages/change-password';
import { LoginPage } from '@/modules/auth/pages/login';
import { ResetPasswordPage } from '@/modules/auth/pages/reset-password';

export const GuestApp = () => {
  const changePasswordLoader = useChangePasswordPageLoader();

  const router = createBrowserRouter([
    {
      path: 'auth/*',
      element: <AuthLayout />,
      HydrateFallback: FullPageSpinner,
      children: [
        { path: 'login', element: <LoginPage /> },
        { path: 'reset-password', element: <ResetPasswordPage /> },
        { path: 'change-password/:token', element: <ChangePasswordPage />, loader: changePasswordLoader },
        { path: '*', element: <Navigate to="/auth/login" /> },
      ],
    },
    { path: '*', element: <Navigate to="/auth/login" /> },
  ]);

  return <RouterProvider router={router} />;
};
