/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';

import { useLocale } from '@/hooks/use-locale';

const beaconIdMap: Record<string, string> = {
  'pl-PL': 'f46e474c-f061-4fd2-8980-d8e12c591a18',
  'es-ES': 'cdd23966-8e61-4779-956a-3b9a3070ee8c',
  default: '57aefd12-9934-425b-8461-303e5d3067e2',
};

export const useBeacon = () => {
  const [isInitialized, setIsInitialized] = useState(false);
  const locale = useLocale();

  useEffect(() => {
    if (isInitialized) {
      (window as any).Beacon('destroy');
    }

    (window as any).Beacon('init', locale in beaconIdMap ? beaconIdMap[locale] : beaconIdMap.default);
    (window as any).Beacon('config', {
      hideFABOnMobile: true,
      enableFabAnimation: false,
      enableSounds: false,
      color: '#192958',
      display: { horizontalOffset: 10, verticalOffset: 10 },
    });

    setIsInitialized(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale]);
};
