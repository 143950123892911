import * as React from 'react';

export const Logo = (props: React.HTMLAttributes<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="138" height="170" fill="none" viewBox="0 0 138 170" role="img" {...props}>
    <path
      fill="#111D3F"
      d="M82.462 134.723c2.584 0 5.428 2.068 5.428 5.171 0 7.241-18.87 29.22-48.857 29.22-20.938 0-34.897-11.894-34.897-33.098 0-16.55 9.048-42.925 16.544-76.8.259-1.293.259-1.552.259-1.81 0-1.035-.776-1.81-2.327-1.81H3.361C1.293 55.596 0 54.044 0 52.234c0-.258 0-.517.259-2.068l1.809-9.827c.517-2.586 2.327-3.878 5.429-4.396 12.149-1.81 20.163-5.947 25.591-11.119 6.98-6.723 9.823-14.74 11.633-20.687C45.496 1.552 47.306 0 49.89 0h16.544c2.326 0 3.36 1.552 3.36 3.362 0 .517 0 .775-.258 2.068l-4.654 23.014c-.516 2.845-.516 3.62-.516 4.138 0 2.585 1.292 3.62 5.945 3.62 3.619 0 7.496 0 12.15-.517 4.652-.518 10.081-1.552 14.992-1.552 2.585 0 7.497.259 7.497 4.138 0 2.844-1.551 5.43-2.585 8.274-2.585 6.982-5.17 10.085-14.734 10.085-3.62 0-7.756-.517-12.15-.776-4.653-.258-9.823-.258-13.7-.258-2.068 0-2.844.776-3.361 2.586-4.395 19.394-10.34 48.614-10.34 65.163 0 9.826 2.585 20.17 13.7 20.17 9.824 0 14.476-8.792 20.68-8.792"
    ></path>
    <path
      fill="#9E2024"
      d="M109.661 141.34q-4.71 4.629-4.71 11.573 0 6.943 4.71 11.572 4.709 4.63 11.815 4.63t11.815-4.63Q138 159.856 138 152.913q0-6.945-4.709-11.573-4.71-4.63-11.815-4.63-7.106 0-11.815 4.63"
    ></path>
  </svg>
);
